"use strict";

import { BOOK_SORT_ORDER_CUSTOM } from "./constants";
import { CHILD_SORT_ORDER_CUSTOM } from "./constants";

/**
 * 索引/内部リンク/左右移動ボタン押下時の寄せ設定を切り替える非同期リクエストを送信します
 * @param {HTMLElement} ele 対象の要素
 */
window.changeElementAlignment = function (ele) {
  sendAjaxRequest("/admin/categories/change_element_alignment", {
    key: ele.name,
    value: ele.value,
  });
};

/**
 * デフォルト表示するカテゴリを変更する非同期リクエストを送信します
 * @param {HTMLElement} ele 対象の要素
 */
window.changeDefaultDisplayCategory = function (ele) {
  sendAjaxRequest("/admin/categories/change_default_display_category", {
    category_id: ele.value
  });
};

/**
 * 簡易登録対象のカテゴリを変更する非同期リクエストを送信します
 * @param {HTMLElement} ele 対象の要素
 */
window.changeQuickRegisterCategory = function (ele) {
  sendAjaxRequest("/admin/categories/change_quick_register_category", {
    category_id: ele.value
  });
};

/**
 * 検索結果を表示するカテゴリを設定する非同期リクエストを送信します
 * @param {HTMLElement} ele 対象の要素
 */
window.changeSearchResultCategory = function (ele) {
  sendAjaxRequest("/admin/categories/change_seearch_result_category", {
    category_id: ele.value
  });
};

/**
 * カテゴリ内の本の並び替え項目を変更する非同期リクエストを送信します
 * @param {HTMLElement} ele 対象の要素
 */
window.changeSort = function (ele) {
  const category_id = ele.getAttribute("data-category-id");
  const sort_id = ele.value;
  sendAjaxRequest(`/admin/categories/${category_id}/update_sort`, { sort_id: sort_id }, function (data) {
    changeCustomLink(ele);
  });
};

/**
 * カテゴリ内の本の並び替えの順を変更する非同期リクエストを送信します
 * @param {HTMLElement} ele 対象の要素
 */
window.changeSortDirection = function (ele) {
  const category_id = ele.getAttribute("data-category-id");
  const direction_id = ele.value;
  sendAjaxRequest(`/admin/categories/${category_id}/update_sort_direction`, { direction_id: direction_id });
};

/**
 * 子カテゴリの並び替え項目を変更する非同期リクエストを送信します
 * @param {HTMLElement} ele 対象の要素
 */
window.changeCategoryHierarchySort = function (ele) {
  const category_id = ele.getAttribute("data-category-id");
  const sort_id = ele.value;
  console.log("changeCategoryHierarchySort");
  sendAjaxRequest(`/admin/categories/${category_id}/update_category_hierarchy_sort`, { sort_id: sort_id }, function (data) {
    changeChildCustomLink(ele);
  });
};

/**
 * 子カテゴリの並び替えの順を変更する非同期リクエストを送信します
 * @param {HTMLElement} ele 対象の要素
 */
window.changeCategoryHierarchySortDirection = function (ele) {
  const category_id = ele.getAttribute("data-category-id");
  const direction_id = ele.value;
  sendAjaxRequest(`/admin/categories/${category_id}/update_category_hierarchy_sort_direction`, { direction_id: direction_id });
};

/**
 * カテゴリの表示・非表示を切り替える非同期リクエストを送信します
 * @param {HTMLElement} ele 対象の要素
 */
window.changeDisplayCategoryDetail = function (ele) {
  const category_id = ele.value;
  sendAjaxRequest(`/admin/categories/${category_id}/update_display_details`, { display_details: ele.checked });
};

/**
 * 最上位カテゴリとするかを切り替える非同期リクエストを送信します
 * @param {HTMLElement} ele 対象の要素
 */
window.changeIsRootCategory = function (ele) {
  const category_id = ele.value;
  sendAjaxRequest(`/admin/categories/${category_id}/update_is_root_category`, { is_root_category: ele.checked });
};

// ロード完了時の処理
document.addEventListener("turbolinks:load", function () {
  // id 属性が 'check_category_display_' で始まるすべてのチェックボックスに対してイベントリスナーを設定
  const checkboxes = document.querySelectorAll("[id^='check_category_display_']");
  checkboxes.forEach(function (checkbox) {
    checkbox.addEventListener("change", function () {
      updateCategoryDisplay(this);
    });
  });

  setCustomLinkDisplay();
  setChildCustomLinkDisplay();
});

/**
 * カテゴリの表示・非表示を更新する非同期リクエストを送信します
 * @param {HTMLElement} checkbox 対象のチェックボックス要素
 */
function updateCategoryDisplay(checkbox) {
  const category_display_id = $(checkbox).val();
  const is_displayed = $(checkbox).prop("checked");
  sendAjaxRequest(`/admin/categories/${category_display_id}/update_is_displayed`, { is_displayed: is_displayed });
}

/**
 * Ajaxリクエストを送信する関数
 * @param {string} url リクエスト先URL
 * @param {object} data 送信するデータ
 * @param {function} successCallback 成功時のコールバック関数
 * @param {function} errorCallback エラー時のコールバック関数
 */
function sendAjaxRequest(url, data, successCallback, errorCallback) {
  $.ajax({
    type: "PATCH",
    url: url,
    data: data,
    success: successCallback || function (data) {},
    error: errorCallback || function (data) {},
  });
}

/**
 * カスタムリンク表示を設定します
 */
function setCustomLinkDisplay() {
  // id 属性が 'select_category_' で始まるすべての要素に対して処理を実行
  const elements = document.querySelectorAll("[id^='select_category_']");
  elements.forEach(function (ele) {
    changeCustomLink(ele);
  });
}

/**
 * カスタムリンクの表示を切り替えます
 * @param {HTMLElement} ele 対象の要素
 */
function changeCustomLink(ele) {
  const category_id = ele.dataset.categoryId;
  const link = document.getElementById("book_sort_" + category_id);

  // カテゴリ選択の値が SORT_ORDER_CUSTOM の場合のみカスタムリンクを表示する
  link.style.display = ele.value == BOOK_SORT_ORDER_CUSTOM ? "block" : "none";
}

/**
 * カスタムリンク表示を設定します
 */
function setChildCustomLinkDisplay() {
  // id 属性が 'select_category_' で始まるすべての要素に対して処理を実行
  const elements = document.querySelectorAll("[id^='select-child-sort-']");
  elements.forEach(function (ele) {
    changeChildCustomLink(ele);
  });
}

/**
 * カスタムリンクの表示を切り替えます
 * @param {HTMLElement} ele 対象の要素
 */
function changeChildCustomLink(ele) {
  const category_id = ele.dataset.categoryId;
  const link = document.getElementById("child-sort-" + category_id);

  // カテゴリ選択の値が SORT_ORDER_CUSTOM の場合のみカスタムリンクを表示する
  link.style.display = ele.value == CHILD_SORT_ORDER_CUSTOM ? "block" : "none";
}